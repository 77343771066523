import React from 'react';

import './Spinner.css';

// https://github.com/loadingio/css-spinner/
// CC0 License

const Spinner = () => (
  <div className="lds-css">
    <div className="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Spinner;
