import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import AuditInfo from '../components/audit/AuditInfo';
import QuestionList from '../components/question/QuestionList';
import CompleteButton from '../components/helpers/CompleteButton';

import { PAGE_TITLE_BASE } from '../helpers/constants';

const AuditPage = props => {
  const { auditId } = props.match.params;
  return (
    <div className='AuditPage container'>
      <Helmet title={`${auditId} | ${PAGE_TITLE_BASE}`} />

      <Breadcrumb>
        <BreadcrumbItem><Link to='/'>Search Result</Link></BreadcrumbItem>
        <BreadcrumbItem active>{auditId}</BreadcrumbItem>
      </Breadcrumb>

      <AuditInfo auditId={auditId}/>
      <QuestionList auditId={auditId}/>
      <CompleteButton auditId={auditId}/>
    </div>
  );
};

AuditPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      auditId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default AuditPage;
