import React from 'react';
import Helmet from 'react-helmet';

import SearchForm from '../components/search/SearchForm';
import SearchResults from '../components/search/SearchResults';

import { PAGE_TITLE_BASE } from '../helpers/constants';

const SearchPage = () => (
  <div className='SearchPage container' style={{width: 1600, minWidth: 1500}}>
    <Helmet title={`Search | ${PAGE_TITLE_BASE}`} />

    <div className='row'>
      <SearchForm className='col-lg-3'/>
      <SearchResults className='col-lg-9'/>
    </div>
  </div>
);

export default SearchPage;
