import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Col } from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import moment from 'moment';
import { connect } from 'react-redux';

import { completeAudit } from '../../actions/complete.actions';
import Spinner from '../helpers/Spinner';

import 'react-dates/lib/css/_datepicker.css';
import './CompleteButton.css';

const COMPLETE_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

class CompleteButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      completionDate: null,
      focused: false,
      comment: '',
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleComplete = this.handleComplete.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Hide confirmation modal on complete request finish
    if (this.props.completeIsFetching !== prevProps.completeIsFetching &&
      this.props.completeIsFetching === false) {
        this.setState({ modal: false });
      }
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  handleComplete(e) {
    e.preventDefault();
    const { dispatch, auditId, audit } = this.props;
    const { comment } = this.state;
    const ncs = (audit && audit.NCs) ? audit.NCs : [];
    const openNcCount = ncs.filter(nc => nc.isOpen).length;
    const completionDate = this.state.completionDate.format(COMPLETE_DATE_FORMAT);
    dispatch(completeAudit(auditId, completionDate, openNcCount, comment));
    this.clearForm();
  }

  clearForm() {
    this.setState({
      completionDate: null,
      comment: '',
    });
  }

  /* Changes for VSOL-14246: Abandoned
  canRequestAuditClose(ncsList) {
	// User can request the audit be closed only if either All NCs are closed, or only Minor NCs are open
	var ncsRegex = RegExp('major|critical', 'gi');
	
	ncsList = ncsList.filter(nc => nc.isOpen && ncsRegex.test(nc.answer || ""));
	
	return ncsList.length === 0;
  }*/

  render() {
    const { isFetching, audit, completeIsFetching } = this.props;
    const { modal, completionDate, comment } = this.state;

    if (isFetching || (!isFetching && !audit)) return null;
    
    const auditFinishDate = (audit && audit.AuditFinishDate) ? audit.AuditFinishDate : '';
    const canRequestClose = (audit && audit.CanRequestClose);
    const ReadOnly = (audit && audit.ReadOnly);
	//const canRequestClose = (audit && audit.CanRequestClose) && this.canRequestAuditClose(audit.NCs);
	
    if (auditFinishDate) {
      const auditFinishDateMoment = moment(auditFinishDate, COMPLETE_DATE_FORMAT);
      return (
        <div className='CompleteButton'>
          <Button color='danger' disabled>
            Audit completed on {auditFinishDateMoment.format('DD/MM/YYYY')}
          </Button>
        </div>
      );
    } else if (!canRequestClose || ReadOnly) {
      return (
        <div className='CompleteButton'>
        </div>
      );
	}

    return (
      <div className='CompleteButton'>
        <Button
          color='danger'
          onClick={this.toggleModal}
        >
          Tell Someone I Am Done
        </Button>

        <Modal isOpen={modal} toggle={this.toggleModal} className='completeModal'>
          <Form>
            <ModalHeader>Tell Someone I Am Done</ModalHeader>
            
            {completeIsFetching &&
            <ModalBody>
              <Spinner />
            </ModalBody>
            }
            {!completeIsFetching &&
            <ModalBody>
              <p>Are you sure you want to complete this job? This will prevent any further editing of NCs.</p>

              <FormGroup row>
                <Label for='completionDate' sm={4}>Completion Date <span className='requiredStar'>*</span></Label>
                <Col sm={8}>
                  <SingleDatePicker
                    date={completionDate}
                    onDateChange={date => this.setState({ completionDate: date })}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    id='completionDate'
                    isOutsideRange={day => isAfterDay(day, moment())}
                    displayFormat='DD/MM/YYYY'
                    hideKeyboardShortcutsPanel
                    numberOfMonths={1}
                    required
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for='comment' sm={4}>Comment <span className='requiredStar'>*</span></Label>
                <Col sm={8}>
                  <Input 
                    type="textarea" 
                    name="comment" 
                    id="comment" 
                    value={comment}
                    onChange={e => this.setState({ comment: e.target.value })}
                    required 
                  />
                </Col>
              </FormGroup>
            </ModalBody>
            }

            <ModalFooter>
              <Button color='danger' onClick={this.handleComplete} disabled={!completionDate || !comment}>Complete</Button>
              <Button color='secondary' onClick={this.toggleModal}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

CompleteButton.propTypes = {
  auditId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  audit: PropTypes.shape({
    AuditFinishDate: PropTypes.string,
    ReadOnly: PropTypes.bool.isRequired,
    NCs: PropTypes.arrayOf(PropTypes.shape({
      isOpen: PropTypes.bool.isRequired,
    })).isRequired,
  }),
  completeIsFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.question.isFetching,
  audit: state.question.items[ownProps.auditId],
  completeIsFetching: state.complete.isFetching,
});

export default connect(mapStateToProps)(CompleteButton);
