import React, { Component } from 'react';
import { Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { userLogout, getCachedUser, minutesUntilExpiry } from '../../helpers/authContext';
import { initialiseSearch } from '../../actions/search.actions';
import logo from '../../assets/img/AQlogo.svg';
import './Header.css';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
        isOpen: false,
        user: null,
        expiryMinutes: 0
    };

    this.toggle = this.toggle.bind(this);
    this.initialiseSearchForm = this.initialiseSearchForm.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.getMinsUntilExpiry = this.getMinsUntilExpiry.bind(this);
    this.logout = this.logout.bind(this);

    if (props.authorised) {
      // Header is called from authorised context - start a time to check whether a user is available
      this.timer = setInterval(this.getUserInfo, 500);
    }
  }

  getUserInfo() {
      let user = getCachedUser();

      if (user != null) {
          // User is now available - stop timer and set state to trigger a re-render
          clearInterval(this.timer);
          this.timer = setInterval(this.getMinsUntilExpiry, 30000);
          this.setState({ user: user, expiryMinutes: minutesUntilExpiry() });
      }
    }

  getMinsUntilExpiry() {
      this.setState({ expiryMinutes: minutesUntilExpiry() });
  }

  componentWillUnmount() {
    // Clear any timer that is present
    if (this.timer) {
      clearInterval(this.timer);
    }      
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  initialiseSearchForm() {
    this.props.dispatch(initialiseSearch());
  }

  logout() {
    // This call logs user entirely out of AD, not just this app. Disable this
    userLogout(); // This clears localStorage
    this.props.dispatch(push('/login'));
  }

  render() {
    let user = this.state.user;
    return (
      <header>
        <div className='container'>
          <Navbar dark expand="md">
            <NavbarBrand tag='div'>
              <Link to='/' onClick={this.initialiseSearchForm}>
                <img src={logo} alt='AsureQuality' className='logo'/>
              </Link>
            </NavbarBrand>
            {user && 
              <span className='navbar-text'>
                <span>logged in as {user.name} ({user.username})</span>
                {(this.state.expiryMinutes < 0)?
                  <span style={{paddingLeft: "4em", color: "#e0c000"}}>Session expired!</span>
                : <span style={{paddingLeft: "4em"}}>Session expires in {this.state.expiryMinutes} minutes</span>
                }
              </span>              
            }
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    activeClassName='active'
                    tag={(props) => <RRNavLink {...props} />}
                    to='/'
                    exact
                    onClick={this.initialiseSearchForm}
                  >
                    Search
                  </NavLink>
                </NavItem>
                <NavItem><NavLink href='#' onClick={this.logout}>Logout</NavLink></NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </header>
    );
  }
}
export default connect()(Header);
