import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';

import { fetchAudits } from '../../actions/search.actions';
import Spinner from '../helpers/Spinner';
import AuditCard from './AuditCard';
import './AuditInfo.css';

class AuditInfo extends Component {
  componentDidMount() {
    const { audit, dispatch, auditId } = this.props;
    if (!audit) {
      dispatch(fetchAudits({ ReferenceId: auditId }));
    }
  }

  render() {
    const { auditId, isFetching, audit } = this.props;

    if (isFetching) {
      return <Spinner />;
    } else if (!audit) {
      return (
        <div className='auditNotFound'>
          <h3>Audit Not Found</h3>
          <p>Job ID {auditId} is invalid or you do not have access.</p>
        </div>
      );
    }

    return (
      <section className='AuditInfo'>
        <ListGroup>
          <ListGroupItem>
            <AuditCard item={audit} />
          </ListGroupItem>
        </ListGroup>
      </section>
    );
  }
};

AuditInfo.propTypes = {
  auditId: PropTypes.string.isRequired,
  audit: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  audit: state.audit.items.find(item => item.id === ownProps.auditId),
  isFetching: state.audit.isFetching,
});

export default connect(mapStateToProps)(AuditInfo);
