import React from 'react';

import './Footer.css';

const Footer = () => (
  <footer>
    <div className='container'>
      <small>&copy; {process.env.REACT_APP_COPYRIGHT}</small>
	  <small style={{paddingLeft: "2em"}}>Version {process.env.REACT_APP_VERSION}</small>
    </div>
  </footer>
);
export default Footer;
