import moment from 'moment';

import { apiConstants, ncStatusConstants } from '../helpers/constants';
import { getTokenAndUser } from '../helpers/authContext';
import { alertActions } from './alert.actions';
import store from '../helpers/store';
import { push } from 'react-router-redux';

export const GET_REQUEST = 'GET_REQUEST';
export const GET_SUCCESS = 'GET_SUCCESS';
export const GET_FAILURE = 'GET_FAILURE';

const endpoint = process.env.REACT_APP_GETENDPOINT;
const baseOptions = {
  method: 'GET',
  cache: 'no-cache',
  mode: 'cors',
  headers: {
    'Access-Control-Request-Method': 'GET',
    'Access-Control-Request-Headers': 'X-Ocp-Apim-Subscription-Key, X-formCode, X-ncsOnly, X-referenceNo, X-Authorization',
    'Ocp-Apim-Subscription-Key': apiConstants.SUBSCRIPTION_KEY,
    'formCode': apiConstants.FORM_CODE,
    'ncsOnly': 'Y',
  }
};

const handleResponse = response => {
//  return response.ok
//    ? response.json()
//    : Promise.reject(`${response.status}: ${response.statusText}`);
  if (response.ok) {
	  return response.json()
  } else {	
      var responseText = `${response.statusText} (${response.status})`;
	  return response.text().then(function(text) {
		  return Promise.reject(`${responseText}: ${text}`);
	  });
  }
};

const tidyNc = nc => {
  // Convert date to moment and sort attachments, then sort by date asc
  const ChangeList = nc.ChangeList.map(response => {
    const responseDate = (response.ResponseDate && response.ResponseDate.length === 8)
      ? moment(response.ResponseDate, 'DDMMYYYY')
      : moment(Number(response.ResponseDate));
    const attachments = response.Attachments.sort((a, b) => {
      if (a.filename < b.filename) { return -1; }
      if (a.filename > b.filename) { return 1; }
      return 0;
    });
    return {
      ...response,
      ResponseDate: responseDate.valueOf(),
      Attachments: attachments,
    };
  }).sort((a, b) => a.ResponseDate - b.ResponseDate);

  const isOpen = nc.nc_status !== ncStatusConstants.CLOSED;

  return {
    ...nc,
    ChangeList,
    isOpen,
  };
};

export const fetchQuestions = (referenceNo) => {
  const request = referenceNo => ({ type: GET_REQUEST, referenceNo });
  const success = results => ({ type: GET_SUCCESS, results, referenceNo });
  const failure = error => ({ type: GET_FAILURE, error });

//    let { token, user } = getTokenAndUser(false);

  //  // Acquire token every time it's used
  //  const { token, user } = getTokenAndUser(false);
  //let token;
  //authContext.acquireToken(authContext.config.clientId, (error, acquiredToken) => {
  //  token = acquiredToken;
  //});
  //const user = authContext.getCachedUser();

  //if( !tokenIsValid(token) ) {
  //  authContext.clearCache()
  //  authContext.login()
  //}

  return dispatch => {
    store.dispatch(getTokenAndUser).then(response => {
      let token = response.token;
      let user = response.user;

      if (!token) {
        // If there is no token then go back to login page
        return store.dispatch(push("/login"));
      }

      dispatch(request(referenceNo));
      const options = {
        ...baseOptions,
        headers: {
          ...baseOptions.headers,
          'Authorization': 'Bearer ' + token,
          'userID': user && user.username,
          referenceNo,
        }
      };
      return fetch(endpoint, options)
        .then(handleResponse)
        .then(
          json => {
            const tidyJson = {
              ...json,
              NCs: json.NCs ? json.NCs.map(tidyNc) : [],
            };
            dispatch(success(tidyJson));
          },
          error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
    });
  };
  //return dispatch => {
  //  dispatch(request(referenceNo));
  //  const options = {
  //    ...baseOptions,
  //    headers: {
  //      ...baseOptions.headers,
  //      'Authorization': 'Bearer ' + token,
  //      'userID': user && user.userName,
  //      referenceNo,
  //    }
  //  };
  //  return fetch(endpoint, options)
  //    .then(handleResponse)
  //    .then(
  //      json => {
  //        const tidyJson = {
  //          ...json,
  //          NCs: json.NCs ? json.NCs.map(tidyNc) : [],
  //        };
  //        dispatch(success(tidyJson));
  //      },
  //      error => {
  //        dispatch(failure(error));
  //        dispatch(alertActions.error(error));
  //      }
  //    );
  //};
};

const shouldFetchQuestions = (state, referenceNo) => {
  const questionsForTheAudit = state.question.items[referenceNo];
  if (questionsForTheAudit) {
    return false;
  }
  return true;
};

export const fetchQuestionsIfNeeded = (referenceNo = '') => {
  return (dispatch, getState) => {
    if (shouldFetchQuestions(getState(), referenceNo)) {
      return dispatch(fetchQuestions(referenceNo));
    }
  };
};
