import {
  COMPLETE_REQUEST,
  COMPLETE_SUCCESS,
  COMPLETE_FAILURE,
} from '../actions/complete.actions';

const initialState = {
  isFetching: false,
  params: null,
  error: null,
};

export const complete = (state = initialState, action) => {
  switch (action.type) {
    case COMPLETE_REQUEST:
      return {
        isFetching: true,
        params: action.params,
        error: null,
      };
    case COMPLETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case COMPLETE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
