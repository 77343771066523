import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
//import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from "history";

import rootReducer from '../reducers';

const initialState = {};

export const history = createBrowserHistory();
const middleware = [
  thunk,
  routerMiddleware(history)
];

const enhancers = [];
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
if (typeof devToolsExtension === 'function') {
  enhancers.push(devToolsExtension());
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store;
