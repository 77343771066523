import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, FormFeedback, Label, Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { SingleDatePicker } from 'react-dates';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';

import { updateResponse } from '../../actions/update.actions';
import Spinner from '../helpers/Spinner';
import { ncStatusConstants } from '../../helpers/constants';
import { getCachedUser, checkLoggedInStatus } from '../../helpers/authContext';

import './ResponseForm.css';

const FILE_LIMIT_MB = 50;
const TOTAL_LIMIT_MB = 74;

class ResponseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerResponse: '',
      auditorComment: '',
      files: [],
      invalidFiles: [],
      submitted: false,
      modal: false,
      responseDate: moment(),
      focused: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleFileChoose = this.handleFileChoose.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.isValidInput = this.isValidInput.bind(this);
    this.dispatchUpdateRequest = this.dispatchUpdateRequest.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseConfirmed = this.handleCloseConfirmed.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleFileChange(e) {
    const { files } = e.target;
    const fileSizeLimit = FILE_LIMIT_MB * 1024 * 1024;
    const totalSizeLimit = TOTAL_LIMIT_MB * 1024 * 1024;
    const validFiles = [];
    const invalidFiles = [];
    let totalSize = 0;

    if (checkLoggedInStatus()) {
        // Session is still live
        Array.prototype.forEach.call(files, file => {
            if (file.size <= fileSizeLimit && totalSize + file.size < totalSizeLimit) {
                validFiles.push(file);
                totalSize += file.size;
            } else {
                invalidFiles.push(file);
            }
        });
        this.setState({ files: validFiles, invalidFiles });
    } else {
        // Session has expired - trigger move to login page
        this.props.dispatch(push("/login"));
    }
  }

  handleFileChoose(e) {
    // If user is not logged in then prevent opening of File dialog and move view to login page
    if (!checkLoggedInStatus()) {
      this.props.dispatch(push("/login"));
      e.preventDefault();
    }
  }


  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  isValidInput() {
    return !!this.state.customerResponse && !!this.state.auditorComment;
  }

  dispatchUpdateRequest(ncStatus) {
    const user = getCachedUser();
    const requestBody = {
      referenceno: this.props.auditId,
      Position: this.props.questionId,
	  RepeaterName: this.props.repeaterName,
	  RepeaterItem: this.props.repeaterItem,
      ResponseDate: this.state.responseDate.valueOf().toString(),
      CustomerResponse: this.state.customerResponse,
      AuditorComments: this.state.auditorComment,
      CurrentNCClosedStatus: ncStatus,
      Attachments: this.state.files,
      AuditorName: user && user.name,
      NCID: this.props.rawNcId,
    };
    this.props.dispatch(updateResponse(requestBody));
  }

  clearForm() {
    this.setState({
      customerResponse: '',
      auditorComment: '',
      files: [],
      invalidFiles: [],
      submitted: false,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    if (!this.isValidInput()) {
      return;
    }
    this.dispatchUpdateRequest(ncStatusConstants.OPEN);
    this.clearForm();
  }

  handleClose(e) {
    e.preventDefault();
    this.setState({ submitted: true });
    if (!this.isValidInput()) {
      return;
    }
    // show confirmation modal
    this.toggleModal();
  }

  handleCloseConfirmed(e) {
    e.preventDefault();
    // hide confirmation modal
    this.toggleModal();
    this.dispatchUpdateRequest(ncStatusConstants.CLOSED);
    this.clearForm();
  }

  render() {
    if (this.props.isFetching) {
      return <Spinner />;
    }

    const { customerResponse, auditorComment, submitted, files, invalidFiles, modal } = this.state;
    return (
      <div className='ResponseForm'>
        <Form>
          <Row>
            <Col sm='12' md='7'>
              <FormGroup>
                <Label for='responseDate'>Response Date <span className='requiredStar'>*</span></Label>
                <SingleDatePicker
                  date={this.state.responseDate}
                  id="responseDate"
                  onDateChange={date => this.setState({ responseDate: date })}
                  focused={this.state.focused}
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  isOutsideRange={day => isAfterDay(day, moment())}
                  initialVisibleMonth={() => moment()}
                  displayFormat='DD/MM/YYYY'
                  hideKeyboardShortcutsPanel
                  numberOfMonths={1}
                  openDirection='up'
                />
                <FormFeedback>required</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for='customerResponse'>Customer Response <span className='requiredStar'>*</span></Label>
                <Input
                  type='textarea'
                  name='customerResponse'
                  id='customerResponse'
                  value={customerResponse}
                  onChange={this.handleChange}
                  placeholder='Add new customer response'
                  invalid={submitted && !customerResponse}
                />
                <FormFeedback>required</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for='auditorComment'>Auditor Comment <span className='requiredStar'>*</span></Label>
                <Input
                  type='textarea'
                  name='auditorComment'
                  id='auditorComment'
                  value={auditorComment}
                  onChange={this.handleChange}
                  placeholder='Add new auditor comment'
                  invalid={submitted && !auditorComment}
                />
                <FormFeedback>required</FormFeedback>
              </FormGroup>
            </Col>

            <Col sm='12' md='5'>
              <FormGroup>
                <h5>Attachments</h5>
                <Label for='fileInput'><span className='btn btn-outline-primary'>Add Attachments</span></Label>
                <input
                  type='file'
                  name='fileInput' id='fileInput'
                  multiple
                  onChange={this.handleFileChange}
                  onClick={this.handleFileChoose}
                  style={{ display: 'none' }}
                />
                <div className='fileSizeInfo'>
                  <small>Maximum size: per file {FILE_LIMIT_MB}MB, total {TOTAL_LIMIT_MB}MB</small>
                </div>
                <ul className='attachmentList'>
                  {files.length > 0 &&
                    Array.prototype.map.call(files, (file, i) => (
                      <li key={i} data-toggle='tooltip' data-placement='top' title={file.name} >
                        {file.name}
                      </li>
                    ))
                  }
                </ul>
                {invalidFiles.length > 0 &&
                  <div className='invalidAttachment'>
                    <div className='fileSizeWarning'>
                      <small>Following files won't be attached - exceeding maximum size</small>
                    </div>
                    <ul className='invalidAttachmentList'>
                      {invalidFiles.map((file, i) => (
                        <li key={i} data-toggle='tooltip' data-placement='top' title={file.name} >
                          <span className='invalidFileName'>{file.name}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                }
              </FormGroup>
            </Col>
          </Row>

          <Button color='primary' className='addResponse' onClick={this.handleSubmit}>Add Response</Button>
          <Button color='danger' className='addResponse' onClick={this.handleClose}>Add Response and Close</Button>

          <Modal isOpen={modal} toggle={this.toggleModal}>
            <ModalHeader>Close Question</ModalHeader>
            <ModalBody>Are you sure you want to close this question? This action cannot be undone.</ModalBody>
            <ModalFooter>
              <Button color='danger' onClick={this.handleCloseConfirmed}>Close</Button>
              <Button color='secondary' onClick={this.toggleModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </Form>
      </div>
    );
  }
};

ResponseForm.propTypes = {
  auditId: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  repeaterName: PropTypes.string,
  repeaterItem: PropTypes.string,
  rawNcId: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ResponseForm.defaultProps = {
	repeaterName: "",
    repeaterItem: "",
    rawNcId: ""
};

const mapStateToProps = state => ({
  isFetching: state.update.isFetching || state.question.isFetching,
});

export default connect(mapStateToProps)(ResponseForm);
