import { apiConstants } from '../helpers/constants';
import { getTokenAndUser } from '../helpers/authContext';
import { alertActions } from './alert.actions';
import { fetchQuestions } from './get.actions';
import store from '../helpers/store';
import { push } from 'react-router-redux';

export const COMPLETE_REQUEST = 'COMPLETE_REQUEST';
export const COMPLETE_SUCCESS = 'COMPLETE_SUCCESS';
export const COMPLETE_FAILURE = 'COMPLETE_FAILURE';

const endpoint = process.env.REACT_APP_COMPLETEENDPOINT;
const baseOptions = {
  method: 'POST',
  cache: 'no-cache',
  mode: 'cors',
  headers: {
    'Access-Control-Request-Method': 'POST',
    'Access-Control-Request-Headers': 'X-Ocp-Apim-Subscription-Key, X-SphereReferenceNo, X-FinishDate, X-FinalNCCount, X-Authorization',
    'Ocp-Apim-Subscription-Key': apiConstants.SUBSCRIPTION_KEY,
    'formCode': apiConstants.FORM_CODE,
  }
};

const handleResponse = response => {
//  return response.ok
//    ? response.text()
//    : Promise.reject(`${response.status}: ${response.statusText}`);
  if (response.ok) {
	  return response.text()
  } else {	
      var responseText = `${response.statusText} (${response.status})`;
	  return response.text().then(function(text) {
		  return Promise.reject(`${responseText}: ${text}`);
	  });
  }
};

export const completeAudit = (referenceNo, completionDate, ncCount, comment) => {
  const request = params => ({ type: COMPLETE_REQUEST, params });
  const success = () => ({ type: COMPLETE_SUCCESS });
  const failure = error => ({ type: COMPLETE_FAILURE, error: error.toString() });

  //const { token, user } = getTokenAndUser(false);
  //let token;
  //authContext.acquireToken(authContext.config.clientId, (error, acquiredToken) => {
  //  token = acquiredToken;
  //});
  //const user = authContext.getCachedUser();
  
  //if( !tokenIsValid(token) ) {
  //  authContext.clearCache()
  //  authContext.login()
  //}

  return dispatch => {
    store.dispatch(getTokenAndUser).then(response => {
      let token = response.token;
      let user = response.user;

      if (!token) {
        // If there is no token then go back to login page
        return store.dispatch(push("/login"));
      }

      dispatch(request({ referenceNo, completionDate, ncCount, comment }));

      const options = {
        ...baseOptions,
        headers: {
          ...baseOptions.headers,
          'Authorization': 'Bearer ' + token,
          'SphereReferenceNo': referenceNo,
          'FinishDate': completionDate,
          'FinalNCCount': ncCount,
          'CloseOutComment': encodeURIComponent(comment),
          'RequestedBy': user && user.username,
        }
      };

      return fetch(endpoint, options)
        .then(handleResponse)
        .then(
          response => {
            // Call Get API to refresh the view
            dispatch(fetchQuestions(referenceNo));
            dispatch(success());
          },
          error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
    });
  };
};
