import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import moment from 'moment';

import { locationIcon, dateIcon } from '../../helpers/icons';
import './AuditCard.css';

const formatReports = reports => {
  if (!reports || reports.length === 0) return null;
  // parse date for sort purpose
  const formatted = reports.map(report => {
    const dateTmp = report.displayName.match(/\((.*)\)$/);
    const date = moment(dateTmp[1], 'DD/MM/YYYY HH:mm');
    const match = report.displayName.match(/^(Letter|Images|Report|Closeout)/);
    const reportType = match ? match[0] : '';
    return {
      ...report,
      date: date.isValid() ? date : null,
      order: reportType === 'Letter' ? 0
        : reportType === 'Images' ? 1
        : reportType === 'Report' ? 2
        : reportType === 'Closeout' ? 3
        : 4,
    };
  });
  formatted.sort((a, b) => {
    // sort by name
    if (a.order - b.order !== 0) return a.order - b.order;
    // sort by date
    if (a.date < b.date) return -1;
    if (a.date > b.date) return 1;
    return 0;
  });
  return formatted;
};

const formatDocuments = documents => {
  if (!documents || documents.length === 0) return null;
  // parse date for sort purpose
  const formatted = documents.map(jobdoc => {
    const dateTmp = jobdoc.displayName.match(/\((.*)\)$/);
    const date = moment(dateTmp[1], 'DD/MM/YYYY HH:mm');
    return {
      ...jobdoc,
      date: date.isValid() ? date : null,
      order: 1,
    };
  });
  formatted.sort((a, b) => {
    // sort by name only
    if (a.displayName < b.displayName) return -1;
    if (a.displayName > b.displayName) return 1;
    return 0;
  });
  return formatted;
};
const AuditCard = props => {
  const { JobID, MasterJobID, Assignee, Auditor, Status, CustomerName, SiteName, AuditType, AuditDate } = props.item;
  const reports = formatReports(props.item.reports);
  const documents = formatDocuments(props.item.documents);
  const InitialNCs = Number(props.item.InitialNCs) || 0;
  const FinalNCs = Number(props.item.FinalNCs) || 0;

  return (
    <div className='AuditCard row'>
      <div className='col-md-5'>
        <div className='row'>
          <div className='jobInfo col-md-4'>
            <h6>Job ID</h6>
            <p>{JobID}</p>
            <div>
              <h6>State</h6>
            </div>
            <div>
              <p>{Status}</p>
            </div>
            <div >
              <h6>Master Job</h6>
            </div>
            <div>
              <p>{MasterJobID}</p>
            </div>
          </div>

          <div className='customerInfo col-md-8'>
            <h6>Customer</h6>
            <p>{CustomerName}</p>
            <div className='row' style={{ marginBottom: '1.35rem' }} >
              <div className='col-auto pr-0'>{locationIcon}</div>
              <p className='col pl-1'> {SiteName}</p>
            </div>
            <div>
              <h6>Audited By</h6>
            </div>
            <div>
              <p>{Auditor}</p>
            </div>
          </div>
        </div>
      </div>


      <div className='auditInfo col-md-4'>
        <h6>Audit</h6>
        <p>{AuditType}</p>
        <div className='row'>
          <div className='col-auto pr-0'>{dateIcon}</div>
          <p className='col pl-1'> {AuditDate}</p>
        </div>
        <div className='row'>
          <span className='ncCount col-auto'>
            Initial NCs <Badge pill color={InitialNCs ? 'warning' : 'primary'}>{InitialNCs}</Badge>
          </span>
          <span className='ncCount col-auto'>
            Outstanding NCs <Badge pill color={FinalNCs ? 'danger' : 'primary'}>{FinalNCs}</Badge>
          </span>
        </div>
        <div>
            <h6>Assigned to</h6>
        </div>
        <div>
            <p>{Assignee}</p>
        </div>
      </div>

      <div className='reports col-md-3'>
        <h6>Reports</h6>
        <ul>
          {reports && reports.map((report, i) => (
            <li key={i}>
              <a
                href={report.uri} rel='noopener noreferrer' target='_blank'
                // prevent click in SearchResults
                onClick={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
              >
                {report.displayName}
              </a>

              {report.attachments && report.attachments.length > 0 &&
                <ul className='attachments'>
                  {report.attachments.map((attachment, i) => (
                    <li key={i}>
                      <a
                        href={attachment.uri} rel='noopener noreferrer' target='_blank'
                        // prevent click in SearchResults
                        onClick={e => e.stopPropagation()}
                        onMouseUp={e => e.stopPropagation()}
                      >
                        {attachment.displayName}
                      </a>
                    </li>
                  ))}
                </ul>
              }
            </li>
          ))}
        </ul>
        {!reports &&
          <Badge color='secondary'>No reports</Badge>
        }
        <div style={{ paddingTop: '1.35rem' }}>
          <h6>Master Job Documents</h6>
          <ul>
            {documents && documents.map((jobdoc, i) => (
              <li key={i}>
                <a
                  href={jobdoc.uri} rel='noopener noreferrer' target='_blank'
                  // prevent click in SearchResults
                  onClick={e => e.stopPropagation()}
                  onMouseUp={e => e.stopPropagation()}
                >
                  {jobdoc.displayName}
                </a>
              </li>
            ))}
          </ul>
          {!documents &&
            <Badge color='secondary'>No Documents</Badge>
          }
        </div>
      </div>
    </div>
  );
};

AuditCard.propTypes = {
  item: PropTypes.shape({
    JobID: PropTypes.string.isRequired,
    MasterJobID: PropTypes.string.isRequired,
    CustomerName: PropTypes.string.isRequired,
    SiteName: PropTypes.string.isRequired,
    AuditType: PropTypes.string.isRequired,
    AuditDate: PropTypes.string.isRequired,
    InitialNCs: PropTypes.string,
    FinalNCs: PropTypes.string,
    reports: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        attachments: PropTypes.arrayOf(
          PropTypes.shape({
            displayName: PropTypes.string.isRequired,
            uri: PropTypes.string.isRequired,
          }).isRequired
        ),
      }).isRequired
    ),
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
      }).isRequired
    ),
	
  }).isRequired,
};
export default AuditCard;
