import React from 'react';
import PropTypes from 'prop-types';

import { openIcon, closedIcon, responseIcon } from '../../helpers/icons';

import './QuestionOutline.css';

function toNCID(ncID) {
    return "NC ID: " + ncID;
}

const QuestionOutline = props => {
  const { question } = props;
  const responsesCount = question.ChangeList ? question.ChangeList.length : 0;
  return (
    <div className='QuestionOutline row'>
      <div className='questionStatus col-auto'>
        {question.isOpen && openIcon}
        {!question.isOpen && closedIcon}
      </div>

      <div className='questionBody col'>
	    <div style={{fontWeight: "bold"}}>{toNCID(question.nc_id)}</div>
        <div>{question.question}</div>
        <div className='actionByDate'>Action By Date: {question.action_by_date || 'N/A'}</div>
      </div>

      <div className='responses col-auto'>
        <div className='row'>
          <div className='col-auto pr-0'>{responseIcon}</div>
          <p className='col pl-1'>{responsesCount}</p>
        </div>
      </div>
    </div>
  );
};

QuestionOutline.propTypes = {
  question: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    question: PropTypes.string.isRequired,
	repeaterName: PropTypes.string,
	repeaterItem: PropTypes.string,
	nc_id: PropTypes.string,
    action_by_date: PropTypes.string,
    ChangeList: PropTypes.array.isRequired,
  }).isRequired,
};


QuestionOutline.defaultProps = {
	repeaterName: "",
	repeaterItem: "",
	nc_id: ""
};

export default QuestionOutline;
