import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { fetchQuestionsIfNeeded } from '../../actions/get.actions';
import Spinner from '../helpers/Spinner';
import QuestionDetail from './QuestionDetail';
import Response from '../response/Response';
import ResponseForm from '../response/ResponseForm';

import './QuestionAndResponse.css';

class QuestionAndResponse extends Component {
  componentDidMount() {
    const { dispatch, auditId } = this.props;
    dispatch(fetchQuestionsIfNeeded(auditId));
  }

  componentDidUpdate(prevProps) {
    if (this.props.auditId !== prevProps.auditId) {
      const { dispatch, auditId } = this.props;
      dispatch(fetchQuestionsIfNeeded(auditId));
    }
  }

  render() {
    const { question, auditIsOpen, readOnly, isFetching, questionId, auditId, repeaterName, repeaterItem, ncId, rawNcId } = this.props;
    if (isFetching && !question) {
      return <Spinner />;
    }
    if (!isFetching && !question) {
      return (
        <div>
          <h3>NC Question Not Found</h3>
          <p>You are not allowed to access, or {questionId} is not an NC question.</p>
        </div>
      );
    }

   question.ncId = ncId;

    const responses = question.ChangeList;
    return (
      <section className='QuestionAndResponse'>
        <QuestionDetail question={question} />

        {responses && responses.length > 0 &&
          <div className='ResponseList'>
            {responses.map((response, i) => (
              <Response key={i} response={response} />
            ))}
          </div>
        }

        {auditIsOpen && !readOnly && question.isOpen &&
          <ResponseForm questionId={questionId} auditId={auditId} repeaterName={repeaterName} repeaterItem={repeaterItem} rawNcId={rawNcId} />
        }
      </section>
    );
  }
};

QuestionAndResponse.propTypes = {
  question: PropTypes.shape({
    ChangeList: PropTypes.array.isRequired,
    isOpen: PropTypes.bool.isRequired,
  }),
  auditIsOpen: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  repeaterName: PropTypes.string,
  repeaterItem: PropTypes.string,
  ncId: PropTypes.string,
  rawNcId: PropTypes.string,
};

QuestionAndResponse.defaultProps = {
	repeaterName: "",
  repeaterItem: "",
  ncId: "",
  rawNcId: ""
};

const getQuestion = (questions, auditId, questionId) => {
  const ncs = (questions[auditId] && questions[auditId].NCs) ? questions[auditId].NCs : [];
  return ncs.find(nc => (nc.repeaterName.concat(nc.repeaterItem, nc.Position) === questionId));
};

const getAuditIsOpen = (questions, auditId) => {
  return !(questions[auditId] && questions[auditId].AuditFinishDate);
};

const getReadOnly = (questions, auditId) => {
  return (questions[auditId] && questions[auditId].ReadOnly) || false;
};

const mapStateToProps = (state, ownProps) => ({
  isFetching: state.question.isFetching,
  auditIsOpen: getAuditIsOpen(state.question.items, ownProps.auditId),
  readOnly: getReadOnly(state.question.items, ownProps.auditId),
  question: getQuestion(state.question.items, ownProps.auditId, ownProps.repeaterName.concat(ownProps.repeaterItem, ownProps.questionId)),
});

export default connect(mapStateToProps)(QuestionAndResponse);
