import {
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_FAILURE,
} from '../actions/update.actions';

const initialState = {
  isFetching: false,
  requestBody: null,
  error: '',
};

export const update = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REQUEST:
      return {
        isFetching: true,
        requestBody: action.requestBody,
        error: null,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
