import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Row, Col } from 'reactstrap';

import Gallery from '../helpers/Gallery';
import { openIcon, closedIcon, responseIcon } from '../../helpers/icons';

import './QuestionDetail.css';

function toGroupAndQuestion(ncID, position) {
    if (ncID === "") {
        return "#" + position;
    } else {
        return "NC ID: " + ncID + " #" + position;
    }
}

const QuestionDetail = ({ question }) => {
  const responsesCount = question.ChangeList ? question.ChangeList.length : 0;
  const images = question.Images
    ? question.Images.map(image => ({
      src: image.URL,
      thumbnail: image.URL,
      alt: image.filename,
    }))
    : [];

  return (
    <div className='QuestionDetail'>
      <div className='row justify-content-between align-items-end'>
        <h3 className='col-auto'>
          {question.isOpen
            ? <Badge color='primary'>{openIcon} Open</Badge>
            : <Badge color='danger'>{closedIcon} Closed</Badge>
          } {toGroupAndQuestion(question.ncId, question.Position)}
        </h3>

        <div className='col-auto row'>
          <div className='col-auto pr-0'>{responseIcon}</div>
          <p className='col-auto pl-1'> {responsesCount} {responsesCount === 1 ? 'response' : 'responses'}</p>
        </div>
      </div>

      <div className='QuestionDetailContent'>
        <Row>
          <Col sm='12' md='7'>
            <h5>Question</h5>
            <p>{question.question || 'N/A'}</p>

            <h5>Answer</h5>
            <p>{question.answer || 'N/A'}</p>

            <h5>Comments</h5>
            <p>{question.comments || 'N/A'}</p>
          </Col>

          <Col sm='12' md='5'>
            <h5>Required Action</h5>
            <p>{question.required_action || 'N/A'}</p>

            <h5>Action By Date</h5>
            <p>{question.action_by_date || 'N/A'}</p>

            <h5>Action Taken</h5>
            {!question.action_taken && !question.describe_action_taken &&
              <p>N/A</p>
            }
            {question.action_taken &&
              <p>{question.action_taken}</p>
            }
            {question.describe_action_taken &&
              <p>{question.describe_action_taken}</p>
            }
          </Col>

          {images.length > 0 &&
            <Col lg='12'>
              <h5>Images</h5>
              <Gallery
                images={images}
                showThumbnails
              />
            </Col>
          }
        </Row>
      </div>
    </div>
  );
};

QuestionDetail.propTypes = {
  question: PropTypes.shape({
    Position: PropTypes.string.isRequired,
	  repeaterName: PropTypes.string,
    repeaterItem: PropTypes.string,
    ncId: PropTypes.string,
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    comments: PropTypes.string.isRequired,
    required_action: PropTypes.string.isRequired,
    action_by_date: PropTypes.string.isRequired,
    action_taken: PropTypes.string.isRequired,
    describe_action_taken: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    ChangeList: PropTypes.array.isRequired,
    Images: PropTypes.array,
  }),
};

QuestionDetail.defaultProps = {
	repeaterName: "",
	repeaterItem: ""
};

export default QuestionDetail;
