import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import { audit } from './audit.reducer';
import { alert } from './alert.reducer';
import { question } from './question.reducer';
import { update } from './update.reducer';
import { complete } from './complete.reducer';

const rootReducer = combineReducers({
  router: routerReducer,
  audit,
  alert,
  question,
  update,
  complete,
});

export default rootReducer;
