import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import { connect } from 'react-redux';

import 'react-dates/initialize';
import moment from 'moment';
import isAfterDay from 'react-dates/lib/utils/isAfterDay';
import 'react-dates/lib/css/_datepicker.css';

import { fetchAuditsIfNeeded } from '../../actions/search.actions';
import { apiConstants } from '../../helpers/constants';

import './SearchForm.css';

class SearchForm extends Component {
  constructor(props) {
    super(props);
    const startDate = props.query.AuditDateMin || apiConstants.DEFAULT_START_DATE;
    const endDate = props.query.AuditDateMax || apiConstants.DEFAULT_END_DATE;
    this.state = {
      focusedInput: null,
      customerName: props.query.CustomerName || '',
      siteName: props.query.SiteName || '',
      auditType: props.query.AuditType || '',
      masterJobId: props.query.MasterJobID || '',
      startDate: moment(startDate, apiConstants.SEARCH_DATE_FORMAT),
      endDate: moment(endDate, apiConstants.SEARCH_DATE_FORMAT),
    };

    this.dispatchSearch = this.dispatchSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  dispatchSearch() {
    const { customerName, siteName, auditType, masterJobId, startDate, endDate } = this.state;
    this.props.dispatch(fetchAuditsIfNeeded({ customerName, siteName, auditType, masterJobId, startDate, endDate }));
  }

  handleChange(e) {
    const { name, value } = e.target;

    if (name === "masterJobId") {
        this.setState({ [name]: value.replace(/[^0-9]/g, '') });
    } else {
        this.setState({ [name]: value });
    }    
  }

  handleSubmit(e) {
    e.preventDefault();
    // if endDate is not specified (null), set the same date as startDate
    const endDate = this.state.endDate || this.state.startDate;
    this.setState({ endDate }, this.dispatchSearch);
  }

  render() {
    const { customerName, siteName, auditType, masterJobId } = this.state;
    return (
      <section className={`SearchForm ${this.props.className}`}>
        <h3>Search Audits</h3>
        <Form onSubmit={this.handleSubmit}>
          <section className='searchSection'>
            <h5>Customer</h5>
            <FormGroup>
              <Label for='customerName'>Customer Name</Label>
              <Input type='text' name='customerName' id='customerName' value={customerName} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for='siteName'>Site Name</Label>
              <Input type='text' name='siteName' id='siteName' value={siteName} onChange={this.handleChange} />
            </FormGroup>
          </section>

          <section className='searchSection'>
            <h5>Audit</h5>
            <FormGroup>
              <Label for='auditType'>Audit Type</Label>
              <Input type='text' name='auditType' id='auditType' value={auditType} onChange={this.handleChange} />
            </FormGroup>
            <FormGroup>
              <Label for='auditDate'>Audit Date</Label>
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="auditDateStart" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="auditDateEnd" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={day => isAfterDay(day, moment())}
                initialVisibleMonth={() => moment().subtract(1, 'month')}
                displayFormat='DD/MM/YYYY'
                hideKeyboardShortcutsPanel
                numberOfMonths={1}
                openDirection='up'
              />
            </FormGroup>
          </section>

          <section className='searchSection'>
            <h5>Job</h5>
            <FormGroup>
              <Label for='masterJobId'>Master Job ID</Label>
              <Input type='text' keyboardtype='numeric' name='masterJobId' id='masterJobId' value={masterJobId} onChange={this.handleChange} maxLength={10}/>
            </FormGroup>
          </section>
          <Button color='primary' size='lg' type='submit' block>Search</Button>
        </Form>
      </section>
    );
  }
};

const mapStateToProps = state => ({
  query: state.audit.query,
});

export default connect(mapStateToProps)(SearchForm);
