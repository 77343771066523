import moment from 'moment';
import isEqual from 'lodash.isequal';

import { apiConstants } from '../helpers/constants';
import { getTokenAndUser } from '../helpers/authContext';
import { alertActions } from './alert.actions'; 
import store from '../helpers/store';
import { push } from 'react-router-redux';

export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'SEARCH_FAILURE';
export const SEARCH_BYID_REQUEST = 'SEARCH_BYID_REQUEST';
export const SEARCH_BYID_SUCCESS = 'SEARCH_BYID_SUCCESS';
export const SEARCH_BYID_FAILURE = 'SEARCH_BYID_FAILURE';
export const INITIALISE_SEARCH = 'INITIALISE_SEARCH';

const endpoint = process.env.REACT_APP_SEARCHENDPOINT;
const baseOptions = {
  method: 'GET',
  cache: 'no-cache',
  mode: 'cors',
  headers: {
    'Access-Control-Request-Method': 'GET',
    'Access-Control-Request-Headers': 'X-Ocp-Apim-Subscription-Key, X-formCode, X-ContactEmail, X-Authorization',
    'Ocp-Apim-Subscription-Key': apiConstants.SUBSCRIPTION_KEY,
    'formCode': apiConstants.FORM_CODE,
  }
};

const handleResponse = response => {
//  return response.ok
//    ? response.json()
//    : Promise.reject(`${response.status}: ${response.statusText}`);
  if (response.ok) {
	  return response.json()
  } else {	
      var responseText = `${response.statusText} (${response.status})`;
	  return response.text().then(function(text) {
		  return Promise.reject(`${responseText}: ${text}`);
	  });
  }
};

export const fetchAudits = (query = {}) => {
  let request, success, failure;

  if (query.ReferenceId) {
    request = query => ({ type: SEARCH_BYID_REQUEST, query });
    success = results => ({ type: SEARCH_BYID_SUCCESS, query, results, receivedAt: moment() });
    failure = error => ({ type: SEARCH_BYID_FAILURE, error });
  } else {
    request = query => ({ type: SEARCH_REQUEST, query });
    success = results => ({ type: SEARCH_SUCCESS, query, results, receivedAt: moment() });
    failure = error => ({ type: SEARCH_FAILURE, error });
  }


    return dispatch => {
        store.dispatch(() => { return getTokenAndUser(true) }).then(response => {
            let token = response.token;
            let user = response.user;

            //console.log("$$$ user = " + (user ? user.username : user));
            //console.log("$$$ token = " + token);

            if (!token) {
              // If there is no token then go back to login page
              return store.dispatch(push("/login"));
            }

            dispatch(request(query)); 
            const options = {
                ...baseOptions,
                headers: {
                    ...baseOptions.headers,
                    'Authorization': 'Bearer ' + token,
                    'ContactEmail': user && user.username,
                    ...query
                }
            };

            return fetch(endpoint, options)
                .then(handleResponse)
                .then(
                    json => {
                        // validate json
                        const items = (Array.isArray(json) && json[0] && json[0].id) ? json : [];
                        dispatch(alertActions.clear());
                        dispatch(success(items));
                    },
                    error => {
                        dispatch(failure(error));
                        dispatch(alertActions.error(error));
                    }
                );
        });
    };
  // Acquire token every time it's used
  //const { token, user } = getTokenAndUser(false);
  //let token;
  //authContext.acquireToken(authContext.config.clientId, (error, acquiredToken) => {
  //  // If the token has already expired then this will be undefined 
  //  token = acquiredToken;
  //});
  //const user = authContext.getCachedUser();
  //if ( !tokenIsValid(token) ) {
  //  authContext.clearCache()
  //  authContext.login()
  //}

  //return dispatch => {
  //  dispatch(request(query));
  //  const options = {
  //    ...baseOptions,
  //    headers: {
  //      ...baseOptions.headers,
  //      'Authorization': 'Bearer ' + token,
  //      'ContactEmail': user && user.userName,
  //      ...query
  //    }
  //  };
  //  return fetch(endpoint, options)
  //    .then(handleResponse)
  //    .then(
  //      json => {
  //        // validate json
  //        const items = (Array.isArray(json) && json[0] && json[0].id) ? json : [];
  //        dispatch(success(items));
  //      },
  //      error => {
  //        dispatch(failure(error));
  //        dispatch(alertActions.error(error));
  //      }
  //    );
  //};
};

const shouldFetchAudits = (state, newQuery) => {
  const { searchPageVisited, lastUpdated, isFetching, query } = state.audit;
  // Fetched previously or is fetching with the same query
  if (searchPageVisited && (lastUpdated || isFetching) && isEqual(query, newQuery)) {
    return false;
  }
  return true;
};

const buildApiQuery = query => {
  const apiQuery = {};
  // Add parameter only if value exists
  if (query.auditId) {
    apiQuery.ReferenceId = query.auditId;
    // If id is specified, ignore other parameters
    return apiQuery;
  }
  if (query.customerName) {
    apiQuery.CustomerName = query.customerName;
  }
  if (query.siteName) {
    apiQuery.SiteName = query.siteName;
  }
  if (query.auditType) {
    apiQuery.AuditType = query.auditType;
  }
  if (query.startDate) {
    apiQuery.AuditDateMin = moment(query.startDate).format(apiConstants.SEARCH_DATE_FORMAT);
  }
  if (query.endDate) {
    apiQuery.AuditDateMax = moment(query.endDate).format(apiConstants.SEARCH_DATE_FORMAT);
  }
  if (query.masterJobId) {
    apiQuery.MasterJobID = query.masterJobId;
  }  
  return apiQuery;
};

export const fetchAuditsIfNeeded = (query = {}) => {
  const apiQuery = buildApiQuery(query);
  return (dispatch, getState) => {
    if (shouldFetchAudits(getState(), apiQuery)) {
      return dispatch(fetchAudits(apiQuery));
    }
  };
};

export const initialiseSearch = () => ({ type: INITIALISE_SEARCH });
