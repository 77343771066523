import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAILURE,
  SEARCH_BYID_REQUEST,
  SEARCH_BYID_SUCCESS,
  SEARCH_BYID_FAILURE,
  INITIALISE_SEARCH,
} from '../actions/search.actions';
import { apiConstants } from '../helpers/constants';

const initialState = {
  isFetching: false,
  items: [],
  error: null,
  lastUpdated: null,
  query: {
    AuditDateMin: apiConstants.DEFAULT_START_DATE,
    AuditDateMax: apiConstants.DEFAULT_END_DATE,
  },
  searchById: null,
  searchPageVisited: false,
};

export const audit = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        items: [],
        query: action.query,
        searchPageVisited: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: action.results,
        lastUpdated: action.receivedAt
      };
    case SEARCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        items: [],
        error: action.error
      };
    case SEARCH_BYID_REQUEST:
      return {
        ...state,
        isFetching: true,
        searchById: action.query.ReferenceId,
      };
    case SEARCH_BYID_SUCCESS:
      return {
        ...state,
        isFetching: false,
        // Replace or add an item to existing items
        items: state.items.filter(item => item.id !== action.query.ReferenceId).concat(action.results),
        lastUpdated: action.receivedAt,
        searchById: null,
      };
    case SEARCH_BYID_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case INITIALISE_SEARCH:
      return initialState;
    default:
      return state;
  }
};
