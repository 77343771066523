import moment from 'moment';

export const PAGE_TITLE_BASE = 'Audit Management | AsureQuality';

const SEARCH_DATE_FORMAT = 'YYYY-MM-DD';
export const apiConstants = {
  SUBSCRIPTION_KEY: process.env.REACT_APP_SUBSCRIPTION_KEY,
  FORM_CODE: 'RMP_NZFAP',
  SEARCH_DATE_FORMAT,
  DEFAULT_START_DATE: moment().subtract(29, 'days').format(SEARCH_DATE_FORMAT),
  DEFAULT_END_DATE: moment().format(SEARCH_DATE_FORMAT),
};

export const ncStatusConstants = {
  CLOSED: 'closed',
  OPEN: 'open',
};
