import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';

import { PAGE_TITLE_BASE } from '../helpers/constants';
import AuditInfo from '../components/audit/AuditInfo';
import QuestionAndResponse from '../components/question/QuestionAndResponse';


function toPageQuestionNo(ncID, position) {
    if (ncID === "") {
		return position;
	} else {
        return "NC ID: " + ncID + " " + position;
	}
}

const QuestionPage = props => {
  const { auditId, questionId } = props.match.params;
  const params = new URLSearchParams(props.location.search);
  let repeaterName = params.get("repeaterName") || "";
  let repeaterItem = params.get("repeaterItem") || "";
  let ncId = params.get("ncId") || "";
  let rawNcId = params.get("rawNcId") || "";
  
  return (
    <div className='QuestionPage container'>
      <Helmet title={`${questionId} | ${auditId} | ${PAGE_TITLE_BASE}`} />

      <Breadcrumb>
        <BreadcrumbItem><Link to='/'>Search Result</Link></BreadcrumbItem>
        <BreadcrumbItem><Link to={`/audit/${auditId}`}>{auditId}</Link></BreadcrumbItem>
        <BreadcrumbItem active>{toPageQuestionNo(ncId, questionId)}</BreadcrumbItem>
      </Breadcrumb>

      <AuditInfo auditId={auditId}/>
      <QuestionAndResponse
              questionId={questionId}
              repeaterName={repeaterName}
              repeaterItem={repeaterItem}
              auditId={auditId}
              ncId={ncId}
              rawNcId={rawNcId}
      />
    </div>
  );
};

QuestionPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      auditId: PropTypes.string.isRequired,
      questionId: PropTypes.string.isRequired,
	    repeaterName: PropTypes.string,
	    repeaterItem: PropTypes.string,
      ncId: PropTypes.string,
      rawNcId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};


export default QuestionPage;
