export const ALERT_SUCCESS = 'ALERT_SUCCESS';
export const ALERT_ERROR = 'ALERT_ERROR';
export const ALERT_CLEAR = 'ALERT_CLEAR';

const success = message => ({ type: ALERT_SUCCESS, message });
const error = message => ({ type: ALERT_ERROR, message: message.toString() });
const clear = () => ({ type: ALERT_CLEAR });

export const alertActions = {
  success,
  error,
  clear,
};
