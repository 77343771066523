import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { FILTER_OPEN, FILTER_CLOSED, FILTER_ALL } from './QuestionList';

import './QuestionFilter.css';

const QuestionFilter = props => {
  const { questions, applyFilter, currentFilter } = props;
  let className = 'filter col-auto';
  return (
    <div className='QuestionFilter row'>
      <span
        className={className + (currentFilter === FILTER_OPEN ? ' active' : '')}
        onClick={() => applyFilter(FILTER_OPEN)}
      >
        Open <Badge pill >{questions.filter(question => question.isOpen).length}</Badge>
      </span>
      <span
        className={className + (currentFilter === FILTER_CLOSED ? ' active' : '')}
        onClick={() => applyFilter(FILTER_CLOSED)}
      >
        Closed <Badge pill >{questions.filter(question => !question.isOpen).length}</Badge>
      </span>
      <span
        className={className + (currentFilter === FILTER_ALL ? ' active' : '')}
        onClick={() => applyFilter(FILTER_ALL)}
      >
        All <Badge pill >{questions.length}</Badge>
      </span>
    </div>
  );
};

QuestionFilter.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
  })).isRequired,
  applyFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.string.isRequired,
};

export default QuestionFilter;
