import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

//import { useAccessToken, getCachedUser, performRedirect } from './helpers/authContext';
import store, { history } from './helpers/store';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import './assets/css/custom.css';

// Check for & handle redirect from Azure AD after login
//console.info("### index page!!!");

  //alert("Index page: hold...");

  //useAccessToken();
  //performRedirect();

  //authContext.handleWindowCallback();

  //const isCallback = authContext.isCallback(window.location.hash);
  //if (isCallback && !authContext.getLoginError()) {
  //  window.location = authContext._getItem(authContext.CONSTANTS.STORAGE.LOGIN_REQUEST);
  //}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);
registerServiceWorker();
