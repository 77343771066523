import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';

//import PrivateRoute from './components/helpers/PrivateRoute';
import Header from './components/presentation/Header';
import Footer from './components/presentation/Footer';
import Alert from './components/helpers/Alert';

//import LoginPage from './views/LoginPage';
import LoginButton from './views/LoginButton';
import SearchPage from './views/SearchPage';
import AuditPage from './views/AuditPage';
import QuestionPage from './views/QuestionPage';
import NoMatchPage from './views/NoMatchPage';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalInstance, checkLoggedInStatus } from './helpers/authContext';
import { history } from './helpers/store';

const App = () => (
  <Router history={history}>
    <div className="App">
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
            <Header authorised={true}/>
            <Alert />
            <main>
              <Switch>
                <Route exact path='/' component={checkLoggedInStatus()? SearchPage : LoginButton} />
                <Route exact path='/login' component={LoginButton} />
                <Route exact path='/audit/:auditId' component={checkLoggedInStatus()? AuditPage : LoginButton} />
                <Route exact path='/audit/:auditId/:questionId' component={checkLoggedInStatus()? QuestionPage : LoginButton} />
                <Route component={NoMatchPage} />
              </Switch>
            </main>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
            <Header />
            <Alert />
            <main>
                <Switch>
                    <Route exact path='/' component={LoginButton} />
                    <Route exact path='/login' component={LoginButton} />
                    <Route exact path='/audit/:auditId' component={LoginButton} />
                    <Route exact path='/audit/:auditId/:questionId' component={LoginButton} />
                    <Route component={NoMatchPage} />
                </Switch>
            </main>
        </UnauthenticatedTemplate>
      </MsalProvider>
      <Footer />
    </div>
  </Router>
);

export default App;
