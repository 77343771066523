import { apiConstants } from '../helpers/constants';
import { getTokenAndUser } from '../helpers/authContext';
import { alertActions } from './alert.actions';
import { fetchQuestions } from './get.actions';
import { fetchAudits } from './search.actions';
import { ncStatusConstants } from '../helpers/constants';
import store from '../helpers/store';
import { push } from 'react-router-redux';

export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'UPDATE_FAILURE';

const endpoint = process.env.REACT_APP_UPDATEENDPOINT;
const baseOptions = {
  method: 'POST',
  cache: 'no-cache',
  mode: 'cors',
  headers: {
    'Access-Control-Request-Method': 'POST',
    'Access-Control-Request-Headers': 'X-Ocp-Apim-Subscription-Key, X-formCode, X-ncsOnly, X-referenceNo, X-Authorization',
    'Ocp-Apim-Subscription-Key': apiConstants.SUBSCRIPTION_KEY,
    'formCode': apiConstants.FORM_CODE,
  }
};

const readUploadedFile = file => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };
    reader.onload = () => {
      resolve(reader.result.replace(/^data:.*base64,/, ''));
    };
    reader.readAsDataURL(file);
  });
};

const handleResponse = response => {
//  return response.ok
//    ? response
//    : Promise.reject(`${response.status}: ${response.statusText}`);
  if (response.ok) {
	  return response
  } else {	
      var responseText = `${response.statusText} (${response.status})`;
	  return response.text().then(function(text) {
		  return Promise.reject(`${responseText}: ${text}`);
	  });
  }
};

export const updateResponse = (requestBody) => {
  const request = requestBody => ({ type: UPDATE_REQUEST, requestBody });
  const success = () => ({ type: UPDATE_SUCCESS });
  const failure = error => ({ type: UPDATE_FAILURE, error: error.toString() });

  // Acquire token every time it's used
  //const { token } = getTokenAndUser(true);
  //let token;
  //authContext.acquireToken(authContext.config.clientId, (error, acquiredToken) => {
  //  token = acquiredToken;
  //});

  //if ( !tokenIsValid(token) ) {
  //  authContext.clearCache()
  //  authContext.login()
  //}

  return dispatch => {
    store.dispatch(getTokenAndUser).then(response => {
      let token = response.token;
      //let user = response.user;

      if (!token) {
        // If there is no token then go back to login page
        return store.dispatch(push("/login"));
      }

      requestBody.formcode = apiConstants.FORM_CODE;
      dispatch(request(requestBody));

      return Promise.all(Array.prototype.map.call(requestBody.Attachments, file => readUploadedFile(file)))
        .then(contents => {
          const attachments = Array.prototype.map.call(requestBody.Attachments, (file, i) => {
            const nameSplit = file.name.match(/(^.*)(\..[^.]*$)/);
            return {
              DocumentID: requestBody.referenceno,
              Extension: nameSplit[2],
              Name: nameSplit[1],
              $content: contents[i],
            };
          });
          const body = {
            ...requestBody,
            Attachments: attachments,
          };
          const options = {
            ...baseOptions,
            headers: {
              ...baseOptions.headers,
              'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify(body),
          };

          return fetch(endpoint, options);
        })
        .then(handleResponse)
        .then(
          response => {
            dispatch(success());
            dispatch(fetchQuestions(requestBody.referenceno));
            if (requestBody.CurrentNCClosedStatus === ncStatusConstants.CLOSED) {
              // Final NCs number changes so update
              dispatch(fetchAudits({ ReferenceId: requestBody.referenceno }));
            }
          },
          error => {
            dispatch(failure(error));
            dispatch(alertActions.error(error));
          }
        );
    });
  };
};
