import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import { fetchQuestionsIfNeeded } from '../../actions/get.actions';
import Spinner from '../helpers/Spinner';
import QuestionFilter from './QuestionFilter';
import QuestionOutline from './QuestionOutline';

import './QuestionList.css';
import { checkLoggedInStatus } from '../../helpers/authContext';

export const FILTER_OPEN = 'FILTER_OPEN';
export const FILTER_CLOSED = 'FILTER_CLOSED';
export const FILTER_ALL = 'FILTER_ALL';

class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: FILTER_OPEN
    };

    this.applyFilter = this.applyFilter.bind(this);
    this.filterQuestions = this.filterQuestions.bind(this);
  }

  componentDidMount() {
    const { dispatch, auditId } = this.props;
    dispatch(fetchQuestionsIfNeeded(auditId));
  }

  componentDidUpdate(prevProps) {
    if (this.props.auditId !== prevProps.auditId) {
      const { dispatch, auditId } = this.props;
      dispatch(fetchQuestionsIfNeeded(auditId));
    }
  }

  applyFilter(filter) {
    this.setState({ filter });
  }

  filterQuestions(question) {
    switch (this.state.filter) {
      case FILTER_OPEN:
        return question.isOpen;
      case FILTER_CLOSED:
        return !question.isOpen;
      case FILTER_ALL:
      default:
        return true;
    }
  }

  generateNCUrl(question, auditId) {
    if (!checkLoggedInStatus()) {
        return "/login";
    } else {
        let paramNCId = `ncId=${question.nc_id || ""}`;
        let paramRawNCId = `&rawNcId=${question.raw_nc_id || ""}`;
        let paramRepeater = "";

        if (question.repeaterName) {
            paramRepeater = `&repeaterName=${question.repeaterName}&repeaterItem=${question.repeaterItem}`;
        }

        return `/audit/${auditId}/${question.Position}?${paramNCId}${paramRawNCId}${paramRepeater}`;
    }
  }

  render() {
    const { auditId, questions, isFetching } = this.props;
    if (isFetching || !questions) {
      return <Spinner />;
    }
    if (!isFetching && questions && questions.length === 0) {
      return <h3>NC Questions Not Found</h3>;
    }

    return (
      <div className='QuestionList'>
        <h3>NC Questions</h3>

        <QuestionFilter
          questions={questions}
          applyFilter={this.applyFilter}
          currentFilter={this.state.filter}
        />

        <ListGroup>
          {questions.filter(this.filterQuestions).map(question => (
            <ListGroupItem
              key={question.repeaterName.concat(question.repeaterItem, question.Position)}
              action
              onMouseUp={e => { 
                if (e.button === 0) {
                    this.props.dispatch(push(this.generateNCUrl(question, auditId)));
                }
              }}
              >
              <QuestionOutline question={question}/>
            </ListGroupItem>
          ))}
        </ListGroup>
      </div>
    );
  }
}

QuestionList.propTypes = {
  auditId: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    Position: PropTypes.string.isRequired,
	repeaterName: PropTypes.string,
	repeaterItem: PropTypes.string,
    raw_nc_id: PropTypes.string.isRequired,
  })),
  isFetching: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

QuestionList.defaultProps = {
	repeaterName: "",
	repeaterItem: ""
};

const getAuditQuestions = (questions, auditId) => {
  return (questions[auditId] && questions[auditId].NCs) ? questions[auditId].NCs : [];
};

// sort by question id asc
const sortQuestions = (a, b) => {
  const idA = a.Position.toLowerCase();
  const idB = b.Position.toLowerCase();
  if (idA < idB) return -1;
  if (idA > idB) return 1;
  
  const rptA = a.repeaterName.toLowerCase();
  const rptB = b.repeaterName.toLowerCase();
  var diff = rptA.localeCompare(rptA);
  
  if (!diff) {
	  const itemA = rptA? a.repeaterItem || "-1" : "-1";
	  const itemB = rptB? b.repeaterItem || "-1" : "-1";
	  
	  return parseInt(itemA, 10) - parseInt(itemB, 10);
  }
  
  return 0;
};

const mapStateToProps = (state, ownProps) => ({
  questions: getAuditQuestions(state.question.items, ownProps.auditId).sort(sortQuestions),
  isFetching: state.question.isFetching,
});

export default connect(mapStateToProps)(QuestionList);
