import * as msal from "@azure/msal-browser";
//import { useState } from "react";
//import { useMsal } from "@azure/msal-react";
//import { useMsal } from "@azure/msal-react";	
//import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { CacheLookupPolicy } from "@azure/msal-browser";

// Instantiate MSAL AuthenticationContext
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    redirectUri: process.env.REACT_APP_REDIRECTURI,
    authority: process.env.REACT_APP_AUTHORITY, 
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECTURI,
    //navigateToLoginRequestUrl: true,
    system: {
        loggerOptions: {
            loggerCallback(loglevel, message, containsPii) {
                if (message) {
                    console.log(message);
                }                
            },
            piiLoggingEnabled: false,
            logLevel: msal.LogLevel.Verbose,
        } //,
        // How often to renew token: 12 hours
        //tokenRenewalOffsetSeconds: 43200
    }
  },
  cache: {
    cacheLocation: "localStorage",
  }
};
const msalLoginRequest = {
    scopes: [ process.env.REACT_APP_API_SCOPE ],
    forceRefresh: false,
    cacheLookupPolicy: CacheLookupPolicy.AccessTokenAndRefreshToken,
    //cacheLookupPolicy: CacheLookupPolicy.AccessToken,
    redirectUri: process.env.REACT_APP_REDIRECTURI
};

const loginTimeout = 120; // Minutes after login that app can be used before having to log in again

export const msalInstance = new msal.PublicClientApplication(msalConfig);
msalInstance.initialize();

//export function tokenIsValid(token) {
//  //const user = getCachedUser(); // authContext.getCachedUser();

//  // Check that the token exists and hasn't expired
//  let currentDateTime = Math.floor(Date.now() / 1000)  // Convert milliseconds to seconds which is used by the tokenExpiry
//    let tokenExpiry = token.expiresOn; // user.profile.exp
//  const expiryWindowSeconds = 300  // 5 minutes

//  // Ensure the token exists and the expiry is within the configured threshold
//  return token != null && (tokenExpiry - expiryWindowSeconds) > currentDateTime
//}

export function tokenIsValid(token) {
  let currentDateTime = Math.floor(Date.now() / 1000)  // Convert milliseconds to seconds which is used by the tokenExpiry
  let tokenExpiry = token.expiresOn; // user.profile.exp
  const expiryWindowSeconds = 300  // 5 minutes

  // Ensure the token exists and the expiry is within the configured threshold
  return token && ((tokenExpiry - expiryWindowSeconds) > currentDateTime);
}

export function getCachedUser() {
    var userList = msalInstance.getAllAccounts();
    var userCount = userList.length;

  if (userCount === 1) {
        //console.log("###USER");
        //console.log(userList[0]);
        return userList[0];
    } else if (userCount > 1) {
        console.warn("Multiple accounts found - will just use the first");
        return userList[0];
    } else {
        return null;
    }
}

export function userLogin() {
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("login", "" + Date.now());

    return msalInstance.loginRedirect(msalLoginRequest);
//    return msalInstance.loginRedirect(msalLoginRequest).then(() => { 
//        return getCachedUser();
//    }, (error) => {
//        console.warn("Failed to log in user: " + error);
//    });    
}

export function checkLoggedInStatus() {
    var result = localStorage.getItem("loggedIn") === "true";

    if (result) {
        // Has session expiry passed - if so then treated as logged out
        if (isSessionExpired()) {
            result = false;
            localStorage.setItem("loggedIn", "false");
            userLogout();
        }        
    }

    return result;
}

export async function userLogout() {
    localStorage.setItem("loggedIn", "false");
    localStorage.setItem("login", "");

    const loginRequest = {
        account: getCachedUser(),
        postLogoutRedirectUri: msalConfig.auth.redirectUri,
        onRedirectNavigate: (url) => {
            // Return false if you would like to stop navigation after local logout
            return true;
        }
    };

  //console.info("postLogoutRedirectUri = " + loginRequest.postLogoutRedirectUri);

  return msalInstance.logoutRedirect(loginRequest);
}

//export function handleLoginResponse(response) {
//    // Handle login redirect response
//    //console.info(msalInstance);
//    //window.location = msalInstance._getItem(msalInstance.Constants.loginRequest);
//    //let user;

//    // If response is non-null then app has returned from a redirect. If response is null then app was not redirected (ignore).
//    if (response !== null) {
//        //let user = response.account;
//        //console.log("###USER");
//        //console.info(user);
        
//        //window.location = msalConfig.auth.redirectUri
//        //showWelcomeMessage(user.username);
//    }
//}


function getTokenRedirect(request, checkOnly) {
    let tokenReq = {
        ...request,
        account: getCachedUser()
    };

    return msalInstance.acquireTokenSilent(tokenReq)
        .catch(error => {
            console.warn(error);
            console.warn("silent token acquisition fails. acquiring token using redirect");

          if (checkOnly) {
            userLogout();
            return null;
          } else {
            // if (error instanceof msal.InteractionRequiredAuthError) {
              // // fallback to interaction when silent call fails
                // console.info("Token expired? Redirecting...");
                // return msalInstance.acquireTokenRedirect({
                    // ...tokenReq,
                    // prompt: 'login'
                // });
            // } else {
              //console.info("### silent token acquisition fails - cannot redirect");

              //console.warn(error);
              userLogout();
              return null;
            //}
          }

        });
}

function isSessionExpired() {
    // Check whether the current session is expired.
    // Returns true if there is no login date or current time is later than the login timeout
    let startTimeText = localStorage.getItem("login");
    let result = true;

    // Check for timeout since login
    if (startTimeText && /^\d+$/.test(startTimeText)) {
        // Determine expiry time
        let expiryTime = parseInt(startTimeText, 10) + loginTimeout * 60000;

        result = (Date.now() > expiryTime);
    }

    return result;
}

export function minutesUntilExpiry() {
    // Return the number of minutes before session expires
    // Returns true if there is no login date or current time is later than the login timeout
    let startTimeText = localStorage.getItem("login");
    let loggedIn = localStorage.getItem("loggedIn") === "true";
    let result = -1;

    // Check for timeout since login
    if (loggedIn && startTimeText && /^\d+$/.test(startTimeText)) {
        // Determine expiry time
        let expiryTime = parseInt(startTimeText, 10) + loginTimeout * 60000;
        let delta = expiryTime - Date.now();

        if (delta > 0) {
            // Convert delta value from milliseconds to minutes
            result = Math.round(delta / 60000);
        }
    }

    return result;
}

export async function getTokenAndUser(checkOnly) {
    let response = await getTokenRedirect(msalLoginRequest, checkOnly);
    let accToken = null;
    //let tknExpiry = null;

    //console.log("##TOKEN");
    //console.log(response);

    if (response) {
        let tmpToken = response.accessToken || null;

        if (tmpToken) {
            if (!isSessionExpired()) {
                // Not expired - set access token var
                accToken = tmpToken;
            } else if (localStorage.getItem("login")) {
                // Session expired (and we haven't just started this app) - logout user
                //accToken = tmpToken;
                userLogout();
            }
        }
    }

    return { 
        token: accToken,
        user: getCachedUser()
    };
}


//export async function performRedirect() {
//    msalInstance.handleRedirectPromise()
//        .then((response) => {
//            console.log("MSAL redirect done");
//            //console.log(response);
//            //handleLoginResponse(response);
//        })
//        .catch((error) => {
//            // Handle redirect error
//            console.warn("###Failed to handle redirect: " + error);
//        });
//}

//export function useAccessToken() {
//    const { instance, accounts } = useMsal();
//    const [accessToken, setAccessToken] = useState(null);

//    let user = null;

//    if (accounts.length > 0) {
//        const request = {
//            scopes: ["User.Read"],
//            account: accounts[0]
//        };
//        user = accounts[0];
//        instance.acquireTokenSilent(request).then(response => {
//            setAccessToken(response.accessToken);
//        }).catch(error => {
//            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
//            if (error instanceof InteractionRequiredAuthError) {
//                instance.acquireTokenRedirect(request).then(response => {
//                    setAccessToken(response.accessToken);
//                });
//            }
//        });
//    }

//    return {
//        token: accessToken, 
//        user: user
//    };
//}