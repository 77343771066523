import React, { Component } from 'react';
import { connect } from 'react-redux';

import { history } from '../../helpers/store';
import { alertActions } from '../../actions/alert.actions';

import './Alert.css';

class Alert extends Component {
  constructor(props) {
    super(props);

    // clear alert on location change
    history.listen((location, action) => {
      this.props.dispatch(alertActions.clear());
    });
  }

  render() {
    const { alert } = this.props;
    if (!alert.message) {
      return null;
    }
    return (
      <section className='Alert'>
        <div className='container'>
          <div className={`alert ${alert.type}`}>{alert.message}</div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  alert: state.alert
});

const connectedAlert = connect(mapStateToProps)(Alert);
export default connectedAlert;
