import {
  GET_REQUEST,
  GET_SUCCESS,
  GET_FAILURE,
} from '../actions/get.actions';

const initialState = {
  isFetching: false,
  items: [],
  error: null,
};

export const question = (state = initialState, action) => {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        items: {
          ...state.items,
          [action.referenceNo]: action.results,
        },
      };
    case GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
