import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col } from 'reactstrap';

import './Response.css';

const Response = ({ response }) => {
  const responseDate = moment(Number(response.ResponseDate));
  return (
    <div className='Response'>
      <Row className='responseHead'>
        <Col sm='12' md='7'>
          <span className='responseHeadLabel'>Response Date&nbsp;</span>
          <span>{responseDate.format('DD/MM/YYYY')}</span>
        </Col>

        <Col sm='12' md='5'>
          <span className='responseHeadLabel'>Added by&nbsp;</span>
          <span>{response.AuditorName || 'Unknown'}</span>
        </Col>
      </Row>

      <Row>
        <Col sm='12' md='7'>
          <h5>Customer Response</h5>
          <p>{response.CustomerResponse || 'N/A'}</p>

          <h5>Auditor Comment</h5>
          <p>{response.AuditorComments || 'N/A'}</p>
        </Col>

        {response.Attachments && response.Attachments.length > 0 &&
          <Col sm='12' md='5'>
            <h5>Attachments</h5>
            <ul className='attachmentList'>
              {response.Attachments.map((file, i) => (
                <li key={i}>
                  <a
                    href={file.URL}
                    rel='noopener noreferrer'
                    target='_blank'
                    data-toggle='tooltip'
                    data-placement='top'
                    title={file.filename}
                  >
                    {file.filename}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        }
      </Row>

    </div>
  );
};

Response.propTypes = {
  response: PropTypes.shape({
    ResponseDate: PropTypes.number.isRequired,
    CustomerResponse: PropTypes.string,
    AuditorComments: PropTypes.string,
    Attachments: PropTypes.arrayOf(PropTypes.shape({
      filename: PropTypes.string.isRequired,
      URL: PropTypes.string.isRequired,
    })),
    AuditorName: PropTypes.string,
  }).isRequired,
};

export default Response;
