import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';

import Spinner from '../helpers/Spinner';
import AuditCard from '../audit/AuditCard';
import { checkLoggedInStatus } from '../../helpers/authContext';

import './SearchResults.css';

const SearchResults = props => {
  if (!props.searchPageVisited) {
    return (
      <div className={`landing ${props.className}`}>
        <h3 className='align-middle'>Search Results Appear Here</h3>
      </div>
    );
  }

  if (props.isFetching) {
    return (
      <div className={`fetching ${props.className}`}>
        <Spinner />
      </div>
    );
  }

  return (
    <section className={`SearchResults ${props.className}`}>
      <p>{props.audits.length} results</p>
      <ListGroup>
        {props.audits.map(audit => (
          <ListGroupItem
            key={audit.id}
            action
            // imitate anchor to prevent nested anchor
            onMouseUp={e => {
              // If session is expired then redirect to login button
              const url = checkLoggedInStatus()? `/audit/${audit.id}` : "/login" ;
                
              if (e.button === 1 || (e.button === 0 && e.ctrlKey)) {
                // middle click or ctrl + click
                window.open(url);
              } else if (e.button === 0) {
                props.dispatch(push(url));
              }
            }}
          >
            <AuditCard item={audit} />
          </ListGroupItem>
        ))}
      </ListGroup>
    </section>
  );
};

const convertToMoment = date => moment(date, 'DD/MM/YYYY');

const sortAudits = (a, b) => {
  // firstly, sort by CustomerName asc
  const nameA = a.CustomerName.toLowerCase();
  const nameB = b.CustomerName.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  // secondly, sort by AuditDate asc
  const dateA = convertToMoment(a.AuditDate);
  const dateB = convertToMoment(b.AuditDate);
  if (dateA.isBefore(dateB)) return -1;
  if (dateA.isAfter(dateB)) return 1;
  return 0;
};

const mapStateToProps = state => ({
  audits: state.audit.items.sort(sortAudits),
  searchPageVisited: state.audit.searchPageVisited,
  isFetching: state.audit.isFetching,
});

export default connect(mapStateToProps)(SearchResults);
