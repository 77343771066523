import React from "react";
import Helmet from 'react-helmet';
import { Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { withMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { PAGE_TITLE_BASE } from '../helpers/constants';
import { userLogin, checkLoggedInStatus } from '../helpers/authContext';

import './LoginPage.css';

class LoginButton extends React.Component {
    callLogin() {
        console.info("$$$ logging in");
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        //const msalInstance = this.props.msalContext.instance;

        // If a user is not logged in and authentication is not already in progress, invoke login
        if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
            //userLogin();
        }
    }
    componentDidMount() {
        localStorage.setItem("loggedIn", "false");
    }

    //componentDidUpdate() {
    //    //this.callLogin();
    //}

    render() {
        const isAuthenticated = this.props.msalContext.accounts.length > 0;
        let loggedIn = checkLoggedInStatus();

        //const msalInstance = this.props.msalContext.instance;
        if (!isAuthenticated || !loggedIn) {
            //console.info("$$$ NOT AUTH")
            return (
                <div className='LoginPage container'>
                    <Helmet title={`Login | ${PAGE_TITLE_BASE}`} />
                    <h2>Login to Audit Management</h2>
                    <Button color='primary' size='lg' onClick={userLogin}>Login with Azure AD</Button>
                </div>
                );
        } else {

            const redirectTo = sessionStorage.getItem('redirectTo') || '/';

            //console.info("$$$ AUTH - redirecting to: " + redirectTo);
            sessionStorage.setItem('redirectTo', ''); // Clear so that it doesn't affect next time
            return <Redirect to={redirectTo} />
        }
    }
}

export default LoginButton = withMsal(LoginButton);